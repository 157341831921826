
import { defineComponent } from 'vue'
import { editMyAccount } from '@/api/team'
import { getAppName, getUserInfo } from '@/api/base'

export default defineComponent({
    name: 'ChangePassword',
    components: {},
    data () {
        return {
            tabActive: 'base',
            clientUserId: 0,
            realName: '',
            mobile: '',
            username: '',
            password: '',
            passwordConfirm: '',
            canEditPassword: false
        }
    },
    async mounted () {
        if (getAppName() === '蓝工盾') {
            this.canEditPassword = true
        }
        const userInfo = await getUserInfo()
        this.clientUserId = userInfo.userInfo.client_user_id
        this.realName = userInfo.userInfo.real_name
        this.mobile = userInfo.userInfo.mobile
        this.username = userInfo.userInfo.user_name
    },

    methods: {
        async onSubmitChangeBase () {
            if (!this.realName) {
                this.$message.error('请输入姓名')
                return
            }
            if (!this.mobile) {
                this.$message.error('请输入手机号')
                return
            }
            await editMyAccount({
                mobile: this.mobile,
                realName: this.realName,
                clientUserId: this.clientUserId
            })
            this.$message.success('操作成功')
        },
        async onSubmitChangePassword () {
            if (!this.password) {
                this.$message.error('请输入密码')
                return
            }
            if (!this.passwordConfirm) {
                this.$message.error('请输入密码')
                return
            }
            if (this.password !== this.passwordConfirm) {
                this.$message.error('密码输入不一致')
                return
            }
            await editMyAccount({
                username: this.username,
                password: this.password,
                clientUserId: this.clientUserId
            })
            this.$message.success('操作成功')
            this.password = ''
            this.passwordConfirm = ''
        }
    }
})
